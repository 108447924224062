import React from "react";
import PageWrap from "../../components/PageWrap/PageWrap";
import ContentBox from "../../components/ContentBox/ContentBox";
import { Link } from "react-router-dom";

import {FaChartArea, FaChartBar, FaChartLine, FaChartPie, FaRegChartBar, FaTable} from "react-icons/fa";
import LINK from "../../utils/LINK";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { ENV } from '../../ENV'

const StatsPage = () => {

  return (
    <PageWrap title="Přehled analýz"
              breadcrumbs_items={[
                { label: "Analýzy" },
              ]}>


      <Row>
        <Col sm={4}>
          <Link to={LINK.statsContractsCount}>
            <ContentBox>
              <div className="d-flex">
                <FaChartArea size={35} className="me-2 mb-2" style={{flexShrink:0}}/>
                <div style={{alignSelf:"center"}}>Zakázky za dané období</div>
              </div>
            </ContentBox>
          </Link>
        </Col>
        <Col sm={4}>
          <Link to={LINK.statsContractsPrice}>
            <ContentBox>
              <div className="d-flex">
                <FaChartArea size={35} className="me-2 mb-2" style={{flexShrink:0}}/>
                <div style={{alignSelf:"center"}}>Hodnota zakázek za dané období</div>
              </div>
            </ContentBox>
          </Link>
        </Col>

        <Col sm={4}>
          <Link to={LINK.statsContractsContenders}>
            <ContentBox>
              <div className="d-flex">
                <FaChartLine size={35} className="me-2 mb-2" style={{flexShrink:0}}/>
                <div style={{alignSelf:"center"}}>Důvody přiřazení nebo vyřazení uchazečů</div>
              </div>
            </ContentBox>
          </Link>
        </Col>

        {!ENV.NEN && <Col sm={4}>
          <Link to={LINK.statsContractsDocs}>
            <ContentBox>
              <div className="d-flex">
                <FaChartPie size={35} className="me-2 mb-2" style={{flexShrink:0}}/>
                <div style={{alignSelf:"center"}}>Struktura dokumentace u zakázek</div>
              </div>
            </ContentBox>
          </Link>
        </Col>}

        <Col sm={4}>
          <Link to={LINK.statsContractsPlaces}>
            <ContentBox>
              <div className="d-flex">
                <FaRegChartBar size={35} className="me-2 mb-2" style={{flexShrink:0}}/>
                <div style={{alignSelf:"center"}}>Místa realizace zakázek</div>
              </div>
            </ContentBox>
          </Link>
        </Col>

        <Col sm={4}>
          <Link to={LINK.statsContractsStates}>
            <ContentBox>
              <div className="d-flex">
                <FaChartBar size={35} className="me-2 mb-2" style={{flexShrink:0}}/>
                <div style={{alignSelf:"center"}}>Analýza stavů zakázek</div>
              </div>
            </ContentBox>
          </Link>
        </Col>

        <Col sm={4}>
          <Link to={LINK.statsWinnersStates}>
            <ContentBox>
              <div className="d-flex">
                <FaTable size={35} className="me-2 mb-2" style={{flexShrink:0}}/>
                <div style={{alignSelf:"center"}}>Přehled vítězných uchazečů a rozdíl mezi nabídkovou a smluvní cenou</div>
              </div>
            </ContentBox>
          </Link>
        </Col>

        <Col sm={4}>
          <Link to={LINK.statsWinnersStatesSupValue}>
            <ContentBox>
              <div className="d-flex">
                <FaTable size={35} className="me-2 mb-2" style={{flexShrink:0}}/>
                <div style={{alignSelf:"center"}}>Přehled vítězných uchazečů a rozdíl mezi předpokládanou a smluvní cenou</div>
              </div>
            </ContentBox>
          </Link>
        </Col>

        <Col sm={4}>
          <Link to={LINK.statsContractsStartedStates}>
            <ContentBox>
              <div className="d-flex">
                <FaChartPie size={35} className="me-2 mb-2" style={{flexShrink:0}}/>
                <div style={{alignSelf:"center"}}>Zahájené zadávací řízení jednotlivých organizací</div>
              </div>
            </ContentBox>
          </Link>
        </Col>
      </Row>

      {/*<h5>----</h5>*/}

      {/*<Row>*/}
      {/*  <Col sm={4}>*/}
      {/*    <Link to={LINK.statExample}>*/}
      {/*      <ContentBox>*/}
      {/*        <FaChartArea size={35} className="me-2 mb-2"/>*/}
      {/*        Ukázka grafů*/}
      {/*      </ContentBox>*/}
      {/*    </Link>*/}
      {/*  </Col>*/}
      {/*</Row>*/}

    </PageWrap>
  );
}

export default StatsPage;