import React from "react"
import ContentBox from "../../ContentBox/ContentBox"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"
import FilterBtns from "../FilterBtns"
import FilterSelectCompanies from "../Select/FilterSelectCompanies"
import FilterSelectDepartments from "../Select/FilterSelectDepartments"
import FilterDateStart from "../Date/FilterDateStart"
import {FormCheck, FormGroup} from "react-bootstrap";
import {useFilterContextInput} from "../../../context/FilterContext";

interface iFilterBoxStatsProps {
  selectCompanies?: boolean,
  checkboxCanceledCodeState?: boolean
}

const FilterBoxStats = ({selectCompanies, checkboxCanceledCodeState}: iFilterBoxStatsProps) => {
  const { value, setVal } = useFilterContextInput("canceledCodeState")

  function toggleCanceledContracts(e: any) {
    const { checked } = e.target
    setVal(checked)
  }

  return (
    <ContentBox>
      <Row>
        <Col sm={6}>
          {selectCompanies && <FilterSelectCompanies/>}
          <FilterDateStart />
          {checkboxCanceledCodeState &&
            <FormGroup as={Row}
                      className="my-3"
            >
              <Col sm={{span: 10, offset: 4}}>
                <FormCheck
                    label="Zahrnout i zrušené ZŘ"
                    onChange={toggleCanceledContracts}
                    type="checkbox"
                    checked={value}
                    id="withCanceled"
                />
              </Col>
            </FormGroup>
          }
        </Col>
        <Col sm={6}>
          {selectCompanies && <FilterSelectDepartments/>}
        </Col>
      </Row>
      <FilterBtns />
    </ContentBox>
  )
}

export default FilterBoxStats
