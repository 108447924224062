import React, { useEffect } from "react"
import "./app.scss"
import Footer from "../Footer/Footer"
import Header from "../Header/Header"
import { Switch, Route, useLocation } from "react-router-dom"
import { PrivateRoute } from "./PrivateRoute"
import LoginPage from "../../pages/LoginPage/LoginPage"
import HomePageNew from "../../pages/HomePage/HomePageNew"
import ContractsListPage from "../../pages/ContractsListPage/ContractsListPage"
import ContractDetailPage from "../../pages/ContractDetailPage/ContractDetailPage"
import LINK from "../../utils/LINK"
import EventsListPage from "../../pages/EventsListPage/EventsListPage"
import StatsPage from "../../pages/StatsPages/StatsPage"
import AuditPage from "../../pages/AuditPage/AuditPage"
import UserProfilPage from "../../pages/UserProfilPage/UserProfilPage"
import UserManagementPage from "../../pages/UserManagementPage/UserManagementPage"
import ModalInfoQuality from "../Modal/ModalInfoQuality"
import ModalInfoCalcGlobalIndicators from "../Modal/ModalInfoCalcGlobalIndicators"
import ModalCpvCodes from "../Modal/ModalCpvCodes"
import StatsContractsCountPage from "../../pages/StatsPages/StatsContractsCountPage"
import StatsContractsPricePage from "../../pages/StatsPages/StatsContractsPricePage"
import StatsContractsDocsPage from "../../pages/StatsPages/StatsContractsDocsPage"
import StatsContractsPlacesPage from "../../pages/StatsPages/StatsContractsPlacesPage"
import StatsContractsStatesPage from "../../pages/StatsPages/StatsContractsStatesPage"
import StatsContractsWinnersPage from "../../pages/StatsPages/StatsContractsWinnersPage"
import StatsContractsStartedStatesOrganizationsPage from "../../pages/StatsPages/StatsContractsStartedStatesOrganizationsPage"
import StatsContractsContendersPage from "../../pages/StatsPages/StatsContractsContendersPage"
import EventDetailPage from "../../pages/EventDetailPage/EventDetailPage"
import UserChangePassPage from "../../pages/UserChangePassPage/UserChangePassPage"
import StatExamplePage from "../../pages/StatExamplePage/StatExamplePage"
import GlobalIndicatorsPage from "../../pages/GlobalIndicatorsPage/GlobalIndicatorsPage"
import ContractorsPage from "../../pages/ContractorsPage/ContractorsPage"
import LegislativaPage from "../../pages/LegislativaPage/LegislativaPage"
import { useDispatch, useSelector } from "react-redux"
import { selectUserInit, selectUserFetchState, /* selectUserData,*/ } from "../../redux/user/selectors"
import Loading from "../Loading/Loading"
import HelpPage from "../../pages/HelpPage/HelpPage"
import NewsInfoPage from "../../pages/NewsInfoPage/NewsInfoPage"
import ContractorDetailPage from "../../pages/ContractorDetailPage/ContractorDetailPage"
import ContractsListRunningPage from "../../pages/ContractsListRunningPage/ContractsListRunningPage"
import TermsAndConditionsPage from "../../pages/TermsAndConditionsPage/TermsAndConditionsPage";
import { loginFromStore } from "../../redux/user/userAction";
import AuditQualitativeAnalysisPage from "../../pages/AuditPage/AuditQualitativeAnalysisPage";
import { ENV } from '../../ENV'

function App() {
  const dispatch = useDispatch()
  const userInit = useSelector(selectUserInit)
  const { /*error,*/ loading } = useSelector(selectUserFetchState)
  const location = useLocation()
  // const user = useSelector(selectUserData)
  // const isAdmin = user && user.preferred_username === "admin"
  const isAdmin = false

  useEffect(() => {
    dispatch(loginFromStore())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Vyresetování čísla stránky v localStorage při odejití ze stránky
  useEffect(() => {
    if (!location.pathname.includes("probihajici")) {
      window.localStorage.setItem("home", JSON.stringify(0))
    }
    if (!location.pathname.includes("prehled-zakazek")) {
      window.localStorage.setItem("contratsList", JSON.stringify(0))
    }
    if (!location.pathname.includes("terminy-zakazek")) {
      window.localStorage.setItem("eventsList", JSON.stringify(0))
    }
    if (!location.pathname.includes("dodavatele/detail")) {
      window.localStorage.setItem("contractorDetail", JSON.stringify(0))
    }
    if (!location.pathname.includes("audit/dodavatele")) {
      window.localStorage.setItem("contractors", JSON.stringify(0))
    }
    if (!location.pathname.includes("kvalitativni-analyza")) {
      window.localStorage.setItem("qindex", JSON.stringify(0))
    }
    if (!location.pathname.includes("globalni-ukazatele")) {
      window.localStorage.setItem("globalIndicators", JSON.stringify(0))
    }
    if (!location.pathname.includes("analyzy/zakazky-za-obdobi")) {
      window.localStorage.setItem("statsCount", JSON.stringify(0))
    }
    if (!location.pathname.includes("analyzy/hodnota-za-obdobi")) {
      window.localStorage.setItem("statsPrice", JSON.stringify(0))
    }
    if (!location.pathname.includes("analyzy/prirazeni-vyrazeni-uchazecu")) {
      window.localStorage.setItem("statsContenders", JSON.stringify(0))
    }
    if (!location.pathname.includes("analyzy/struktura-dokumentace")) {
      window.localStorage.setItem("statsDocs", JSON.stringify(0))
    }
    if (!location.pathname.includes("analyzy/mista-realizace")) {
      window.localStorage.setItem("statsPlaces", JSON.stringify(0))
    }
    if (!location.pathname.includes("analyzy/stavy-zakazek")) {
      window.localStorage.setItem("statsStates", JSON.stringify(0))
    }
    if (!location.pathname.includes("prehled-viteznych-uchazecu-nabidkova-cena")) {
      window.localStorage.setItem("statsWinners", JSON.stringify(0))
    }
    if (!location.pathname.includes("prehled-viteznych-uchazecu-predpokladana-cena")) {
      window.localStorage.setItem("statsWinnersSupValue", JSON.stringify(0))
    }
  })

  if (!userInit || loading) {
    return <Loading />
  }

  return (
    <div className="App">
      <Header />
      <Switch>
        <Route path={LINK.login} exact component={LoginPage} />
        <Route path={LINK.changePass} exact component={UserChangePassPage} />

        <PrivateRoute path={LINK.home} exact component={HomePageNew} />
        <PrivateRoute
          path={LINK.contractsList}
          exact
          component={ContractsListPage}
        />
        <PrivateRoute
          path={LINK.contractsListRunning}
          exact
          component={ContractsListRunningPage}
        />
        <PrivateRoute
          path={`${LINK.contractDetail}/:id`}
          exact
          component={ContractDetailPage}
        />

        {!ENV.NEN && <PrivateRoute path={LINK.eventsList} exact component={EventsListPage}/>}
        {!ENV.NEN && <PrivateRoute
          path={`${LINK.eventDetail}/:id`}
          exact
          component={EventDetailPage}
        />}

        <PrivateRoute path={LINK.stats} exact component={StatsPage} />
        <PrivateRoute
          path={LINK.statsContractsCount}
          exact
          component={StatsContractsCountPage}
        />
        <PrivateRoute
          path={LINK.statsContractsPrice}
          exact
          component={StatsContractsPricePage}
        />
        <PrivateRoute
          path={LINK.statsContractsContenders}
          exact
          component={StatsContractsContendersPage}
        />
        {!ENV.NEN && <PrivateRoute
            path={LINK.statsContractsDocs}
            exact
            component={StatsContractsDocsPage}
        />}
        <PrivateRoute
          path={LINK.statsContractsPlaces}
          exact
          component={StatsContractsPlacesPage}
        />
        <PrivateRoute
          path={LINK.statsContractsStates}
          exact
          component={StatsContractsStatesPage}
        />
        <PrivateRoute
          path={LINK.statsWinnersStates}
          exact
          component={StatsContractsWinnersPage}
        />
        <PrivateRoute
            path={LINK.statsWinnersStatesSupValue}
            exact
            component={StatsContractsWinnersPage}
        />
        <PrivateRoute
            path={LINK.statsContractsStartedStates}
            exact
            component={StatsContractsStartedStatesOrganizationsPage}
        />

        <PrivateRoute
          path={LINK.audit}
          exact
          component={AuditPage}
        />
        {<PrivateRoute
            path={LINK.qualitativeAnalysis}
            exact
            component={AuditQualitativeAnalysisPage}
        />}

        <PrivateRoute
          path={LINK.statExample}
          exact
          component={StatExamplePage}
        />

        <PrivateRoute path={LINK.globalIndicators} exact component={GlobalIndicatorsPage} />

        <PrivateRoute
          path={LINK.contractors}
          exact
          component={ContractorsPage}
        />

        <PrivateRoute
          path={`${LINK.contractorDetail}/:id`}
          exact
          component={ContractorDetailPage}
        />

        <PrivateRoute
          path={LINK.legislativa}
          exact
          component={LegislativaPage}
        />
        <PrivateRoute
            path={LINK.terms}
            exact
            component={TermsAndConditionsPage}
        />
        <PrivateRoute path={LINK.help} exact component={HelpPage} />
        <PrivateRoute path={LINK.newsInfo} exact component={NewsInfoPage} />

        <PrivateRoute
          path={LINK.userManagement}
          exact
          component={UserManagementPage}
          redirect={!isAdmin ? LINK.profil : ""}
        />

        <PrivateRoute
          path={LINK.profil}
          exact
          component={UserProfilPage}
          redirect={isAdmin ? LINK.userManagement : ""}
        />

        <Route
          path={LINK.notFound404}
          component={() => <div>404 Stránka nenalezena.</div>}
        />
        <Route
          component={() => {
            window.location.href = LINK.notFound404
            return null
          }}
        />
      </Switch>

      <ModalInfoQuality />
      <ModalInfoCalcGlobalIndicators />
      <ModalCpvCodes />

      <Footer />
    </div>
  )
}

export default App
