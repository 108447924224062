import React from "react"
import { ResponsiveBar } from "@nivo/bar"
import { colorSchemes } from "@nivo/colors"
import AlertInfo from "../Alerts/AlertInfo"
import { iStatsChart } from "../../api/intrefaces"
import {calcHighestValue} from "../../utils/prepareStats";
import {useDispatch} from "react-redux";
import {useFilterContext} from "../../context/FilterContext";
import {setFilterCharts} from "../../redux/filters/filtersSlice";

interface iBarContracts {
  data: iStatsChart[]
  legend?: string
  priceChart?: boolean
  colorByLabel?: boolean
  horizontal?: boolean,
  filterName?: string
}

const MAX_BOTTOM_LABEL_LEN = 25

const BarStats = ({ data, legend = "počet zakázek", priceChart, colorByLabel, horizontal, filterName }: iBarContracts) => {
  const dispatch = useDispatch()
  const {contextStatsName} = useFilterContext()

  if (!data || data.length === 0) {
    return <AlertInfo msg="Žádná data" />
  }

  const labels = data
    .map(d => d.label)
    .sort()
    .reverse()

  const getColor = (index: number, label: string) => {
    const colorIndex = colorByLabel ? labels.indexOf(label) : index
    return colorSchemes.accent[colorIndex % colorSchemes.accent.length]
  }

  function handleClick(node: any) {
    if (filterName) {
      dispatch(setFilterCharts(
          { name: contextStatsName, filters: {name: filterName, value: node.data.id.toString()}}
      ))
      && dispatch(setFilterCharts(
          { name: contextStatsName, filters: {name: "nutsCodeDescription", value: node.data.label.toString()} }));
    }
  }

  const legendAndFormat = calcHighestValue(data)

  return (
    <ResponsiveBar
      data={data as any}
      keys={["value"]}
      indexBy={"label"}
      // maxValue={data.length < 10 ? 10 : "auto"}
      layout={horizontal ? "horizontal" : "vertical"}
      groupMode={"grouped"}
      margin={{
        top: 20,
        right: 20,
        bottom: 150,
        left: horizontal ? 120 : 70,
      }}
      padding={data.length < 10 ? 0.8 : 0.3} //between group bars
      colors={({ id, data, index }) => getColor(index, data.label as string)}
      axisTop={null}
      axisRight={null}
      axisBottom={
        horizontal
          ? {
              tickSize: priceChart ? 2 : 5,
              tickPadding: priceChart ? -2 : 5,
              tickRotation: 0,
              legend: priceChart ? legendAndFormat.legend : "počet zakázek",
              legendPosition: "middle",
              legendOffset: 60,
              format: val => priceChart ? (val/legendAndFormat.format).toLocaleString() : Number(val).toLocaleString()
            }
          : {
              tickSize: 5,
              tickPadding: 5,
              tickRotation: -30,
              format: label =>
                label.length > MAX_BOTTOM_LABEL_LEN + 3
                  ? label.substr(0, MAX_BOTTOM_LABEL_LEN) + "..."
                  : label,
              // legend: "Druh ZŘ",
              // legendPosition: "end",
              // legendOffset: 40
            }
      }
      axisLeft={
        horizontal
          ? {
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              format: label => label
              // format: label =>
              //   label.length > MAX_BOTTOM_LABEL_LEN + 3
              //     ? label.substr(0, MAX_BOTTOM_LABEL_LEN) + "..."
              //     : label,
              // legend: "Druh ZŘ",
              // legendPosition: "end",
              // legendOffset: 40
            }
          : {
              tickSize: priceChart ? 2 : 5,
              tickPadding: priceChart ? -2 : 5,
              tickRotation: 0,
              legend: priceChart ? legendAndFormat.legend : legend,
              legendPosition: "middle",
              legendOffset: -60,
              format: val => priceChart ? (val/legendAndFormat.format).toLocaleString() : Number(val).toLocaleString()
            }
      }
      enableLabel={false}
      onClick={(node, event) => {
        console.log("nodee click", node);
        handleClick(node)
      }}
      tooltip={({ id, value, color, index, indexValue, data }) => (
        <span>
          <svg width={20} height={20}>
            <circle fill={color} r={5} cx={10} cy={10} />
          </svg>
          <strong>{indexValue}</strong> - {Number(value).toLocaleString()}&nbsp;
          {priceChart ? " Kč" : ""}
        </span>
      )}
      animate={true}
    />
  )
}

export default BarStats
