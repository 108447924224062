import React, { useContext, useState, createContext, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectFilters,
  selectFiltersCharts,
  selectTotalCounts,
  setFilter,
} from '../redux/filters/filtersSlice'
import { selectContracts } from "../redux/contracts/contractsSlice";
import {iContract} from "../api/intrefaces";

export interface iFilter {
  [name: string]: any
}

interface iContracts {
  [id: number]: iContract
}

const defState = {
  contextName: '',
  filters: {} as iFilter,
  activeFilters: {} as iFilter,
  setFilter: (state: iFilter) => {},
  updateFilter: (state: Partial<iFilter>) => {},
  clearFilter: () => {},
  applyFilter: () => {},
  contextStatsName: '',
  activeFiltersChart: {} as iFilter,
  isEmpty: true,
  openFilter: false,
  setOpenFilter: (isOpen: boolean) => {},
  page: 1,
  limit: 10,
  totalCount: {} as iFilter,
  contracts: {} as iContracts,
  contractsList: {} as iContract[],
  contractDetail: {} as iContracts,
  error: "",
  loading: false,
}

export const FilterContext = createContext(defState)
export const useFilterContext = () => useContext(FilterContext)
export const useFilterContextInput = (inputName: string) => {
  const { filters, updateFilter } = useFilterContext()
  return {
    value: filters[inputName] || '',
    setVal: (value: any) => updateFilter({ [inputName]: value !== '' ? value : undefined }),
  }
}

interface iFilterContextProvider {
  name: string
  children: React.ReactNode
}
export const FilterContextProvider = ({ name, children }: iFilterContextProvider) => {
  const activeFilters = useSelector(selectFilters(name))
  const {
    error,
    loading,
    contractsList,
    contracts,
    contractDetail
  } = useSelector(selectContracts(name))
  const activeFiltersChart = useSelector(selectFiltersCharts(name ? name : ""))
  const totalCounts = useSelector(selectTotalCounts(name ? name : ""))
  const [contextFilters, setContextFilters] = useState<iFilter>(activeFilters)

  const isEmpty = Object.keys(contextFilters).length === 0
  const [openFilter, setOpenFilter] = useState(!isEmpty)

  const dispatch = useDispatch()

  const providerValue = useMemo(() => ({
    contextName: name,
    filters: contextFilters,
    activeFilters: activeFilters,
    setFilter: setContextFilters,
    updateFilter: (values: any) => {
      setContextFilters((state) => ({ ...state, ...values }))
    },
    clearFilter: () => {
      setContextFilters({})
      dispatch(setFilter({name: name, filters: {}}))
    },
    applyFilter: () => {
      setContextFilters(contextFilters)
      dispatch(setFilter({ name: name, filters: contextFilters }))
    },
    contextStatsName: name || '',
    activeFiltersChart: activeFiltersChart,
    isEmpty,
    openFilter,
    setOpenFilter,
    page: 1,
    limit: 10,
    totalCount: totalCounts,
    contracts: contracts,
    contractsList: contractsList,
    contractDetail: contractDetail,
    error: error,
    loading: loading,
  }), [
    contextFilters,
    name,
    activeFilters,
    dispatch,
    isEmpty,
    openFilter,
    activeFiltersChart,
    totalCounts,
    contracts,
    contractsList,
    contractDetail,
    error,
    loading
  ])

  return <FilterContext.Provider value={providerValue}>{children}</FilterContext.Provider>
}