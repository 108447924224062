import React from "react";
import PageWrap from "../../components/PageWrap/PageWrap";
import FilterBoxGlobalIndicators from "../../components/Filters/Box/FilterBoxGlobalIndicators";
import Paginator from "../../components/Paginator/Paginator";
import StatContractsDates from "../../components/Stats/StatContractsDates"
import ContractsListFiltered from "../../components/ContractsListLast/ContractsListFiltered";
import ContentBox from "../../components/ContentBox/ContentBox";
import GlobalIndicatorsQuestionMark from "../../components/GlobalIndicatorsQuestionMark/GlobalIndicatorsQuestionMark";
import {FilterContextProvider} from "../../context/FilterContext";
import {PAGE_TYPES} from "../../context/enumPageTypes";
import FilterOrder from "../../components/Filters/FilterOrder";
import Row from "react-bootstrap/Row";

const GlobalIndicatorsPage = () => {
  return (
      <PageWrap
          title="Globální ukazatele"
          breadcrumbs_items={[{ label: "Globální ukazatele" }]}
      >
        <FilterContextProvider name={PAGE_TYPES.globalIndicators}>
          <FilterBoxGlobalIndicators />

          <div className="row-end align-items-center">
            Nápověda
            <GlobalIndicatorsQuestionMark/>
          </div>

          <StatContractsDates />
          <Row className="mb-2 justify-content-end flex-order">
            <FilterOrder maximWidth={260}
                         orderOptions={[
                           {label: "Datum zahájení - vzestupně", value: "date_start ASC"},
                           {label: "Datum zahájení - sestupně", value: "date_start DESC"},
                           {label: "Číslo Profilu - vzestupně", value: "system_number ASC"},
                           {label: "Číslo Profilu - sestupně", value: "system_number DESC"},
                           {label: "Název veřejné zakázky (A-Z)", value: "contract_name ASC"},
                           {label: "Název veřejné zakázky (Z-A)", value: "contract_name DESC"},
                           {label: "Název zadavatele (A-Z)", value: "company_name ASC"},
                           {label: "Název zadavatele (Z-A)", value: "company_name DESC"},
                           {label: "Druh ZŘ (A-Z)", value: "name_procedure ASC"},
                           {label: "Druh ZŘ (Z-A)", value: "name_procedure DESC"},
                           {label: "Počet nabídek - vzestupně", value: "offer_count ASC"},
                           {label: "Počet nabídek - sestupně", value: "offer_count DESC"},
                         ]}
            />
          </Row>
          <ContentBox partHeight title="Zakázky">
            <ContractsListFiltered />
          </ContentBox>

          <Paginator />
        </FilterContextProvider>
      </PageWrap>
  );
};

export default GlobalIndicatorsPage;
